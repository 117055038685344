/*
SCREENSIZE
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 25, 2022
UPDATE DATE: May 25, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ScreenSize | Check your screen size!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

%anim{
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

$normalColor: #AAA;
$holidayColor: #D10000;
$longColor: #4FA83D;

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;

      > div{
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;

        > span{
          display: block;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
        }
      }
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > ul{
    margin: -5px;
    overflow: auto;

    > li{
      position: relative;
      padding: 5px;
      float: left;
      box-sizing: border-box;

      &:nth-child(1){
        width: 48%;

        > a{
          float: left;
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;

          > svg{
            display: block;
            width: 164px;
            height: 40px;
          }
        }

        .today{
          margin-left: 174px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          // border: 1px solid #CCC;
          // background: #FFF;
          background: #EEE;
          padding: 0 10px;
          border-radius: 5px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @extend %anim;

          &:hover{
            background: #DDD;
            color: #888;

            > strong{
              color: #555;
            }
          }

          > strong{
            color: #666;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5){
        width: 13%;
      }
      
      > label{
        background: #fff;
        font-size: 11px;
        left: 16px;
        line-height: 11px;
        padding: 0 5px;
        position: absolute;
        top: 1px;
        z-index: 1;
      }

      > button{
        background: #666;
        color: #FFF;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover{
          background: #333;
        }

        span{
          display: block;
          font-size: 9px;
          font-weight: normal;
          margin: 0 0 1px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > svg{
          display: inline-block;
          vertical-align: middle;
          margin: -3px 5px 0 0;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.mid{
  position: absolute;
  // padding: 0 40px;
  top: 50%;
  transform: translate(0, -50%);
  left: 20px;
  right: 20px;
  margin: 0 auto;
  text-align: center;
  color: #666;

  > h1{
    font-weight: normal;
    font-size: 26px;
    line-height: 34px;
    max-width: 85%;
    margin: 0 auto;
  }

  > div{
    font-size: 18px;
    line-height: 22px;
    max-width: 85%;
    margin: 10px auto 0 auto;
  }

  > ul{
    padding: 15px 0 5px 0;

    > li{
      display: inline-block;
      vertical-align: middle;

      > ul{
        padding: 4.5px;

        > li{
          display: inline-block;
          vertical-align: middle;
          padding: .5px;

          > div{
            position: relative;
            color: #FFF;
            width: 105px;
            min-height: 105px;
            padding: 7.5px;
            background: #AAA;
            text-align: left;
            box-sizing: border-box;

            &.holiday{
              background: $holidayColor;
            }

            &.sandwich{
              background: $longColor;
            }

            > span{
              border: 10px solid transparent;
              border-bottom: 10px solid #FFF;
              bottom: auto;
              height: 0;
              position: absolute;
              right: -10px;
              top: -10px;
              transform: rotate(45deg);
              width: 0;
            }

            > ul{
              > li{
                &:nth-child(1){
                  font-size: 13px;
                  line-height: 17px;

                  > span{
                    // display: block;
                    margin-right: 4px;
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 100;
                  }
                }

                &:nth-child(2){
                  padding-top: 4px;
                  font-weight: bold;
                  font-size: 11px;
                  line-height: 15px;
                  text-transform: uppercase;
                }


                &:nth-child(3){
                  padding-top: 3px;
                  font-size: 11px;
                  line-height: 15px;
                }
              }
            }
          }
        }
      }

      &.current{
        > ul{
          > li{
            > div{
              padding: 10px;
              width: 130px;
              min-height: 130px;
              background: $holidayColor;
              box-shadow: 0 15px 20px -10px rgba(0,0,0,.25);

              > ul{
                > li{
                  &:nth-child(1){
                    > span{
                      font-size: 26px;
                      line-height: 30px;
                    }
                  }
  
                  &:nth-child(2){
                    font-size: 13px;
                    line-height: 17px;
                  }
  
  
                  &:nth-child(3){
                    font-size: 13px;
                    line-height: 17px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.loading{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px;

  > ul{
    width: 48px;
    height: 48px;
    margin: 0 auto;
    padding-bottom: 5px;

    > li{
      position: relative;
      float: left;
      width: 33.33%;
      height: 16px;

      > div{
        width: 12px;
        height: 12px;
        background: #CCC;
        border-radius: 3px;
        margin: 0 auto;
        animation: loading 1s infinite ease-in-out;
      }

      &:nth-child(2){
        > div{
          animation-delay: 0.1s;
        }
      }

      &:nth-child(3){
        > div{
          animation-delay: 0.2s;
        }
      }

      &:nth-child(4){
        > div{
          animation-delay: 0.3s;
        }
      }

      &:nth-child(5){
        > div{
          animation-delay: 0.4s;
        }
      }

      &:nth-child(6){
        > div{
          animation-delay: 0.5s;
        }
      }

      &:nth-child(7){
        > div{
          animation-delay: 0.6s;
        }
      }

      &:nth-child(8){
        > div{
          animation-delay: 0.7s;
        }
      }

      &:nth-child(9){
        > div{
          animation-delay: 0.8s;
        }
      }
    }
  }
}

@keyframes loading{
  0% { opacity: .25; transform: scale(1, 1); }
  50% { opacity: 1; transform: scale(1.2, 1.2); }
  100% { opacity: .25; transform: scale(1, 1); }
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }

  > div{
    margin: 2.5px 0 0 0;
    font-size: 10px;
    line-height: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){
  .mid{
    left: 0;
    right: 0;

    > ul{
      white-space: nowrap;
      overflow: scroll;
      padding-bottom: 15px;
      margin-bottom: -10px;

      > li{
        > ul{
          > li{
            white-space: normal;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 44%;

          .today{
            font-size: 15px;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5){
          width: 14%;
        }
      }
    }
  }

  .mid{
    > h1,
    > div{
      max-width: 75%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5){
          width: 25%;
        }
      }
    }
  }

  .mid{
    > h1,
    > div{
      max-width: none;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 767px){
  .mid{
    position: static;
    transform: none;
    left: 0;
    right: 0;
    // padding-bottom: 121px;

    > h1,
    > div{
      max-width: none;
      padding-left: 20px;
      padding-right: 20px;
    }

    > ul{
      white-space: nowrap;
      overflow: scroll;
      padding-bottom: 15px;
      margin-bottom: -10px;

      > li{
        > ul{
          > li{
            white-space: normal;
          }
        }
      }
    }
  }

  footer{
    position: static;
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5){
          width: 25%;
        }
      }
    }
  }

  .mid{
    padding-top: 140px;

    > h1{
      font-size: 13px;
      line-height: 17px;
    }

    > div{
      font-size: 10px;
      line-height: 14px;
    }
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }

  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;

          > a{
            > svg{
              width: 113px;
            }
          }

          .today{
            font-size: 12px;
            margin: 0 0 0 123px;
          }
        }

        &:nth-child(2){
          width: 50%;
        }

        &:nth-child(3){
          width: 50%;
        }

        &:nth-child(4){
          width: 50%;
        }

        &:nth-child(5){
          width: 50%;
        }
      }
    }
  }

  .mid{
    padding-top: 190px;

    > h1{
      font-size: 18px;
      line-height: 24px;
    }

    > div{
      font-size: 14px;
      line-height: 18px;
    }
  }
  
  footer{
    padding: 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/